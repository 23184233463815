export default [
    {
        path: "/keyword-manager/keyword-research",
        name: "keyword-manager-keyword-research",
        component: () => import("@/views/keyword-manager/KeywordResearch.vue"),
        meta: {
            resource: "ACL",
            action: "all",
        },
    },
    {
        path: "/keyword-manager/keyword-import",
        name: "keyword-manager-keyword-import",
        component: () => import("@/views/keyword-manager/KeywordImport.vue"),
        meta: {
            resource: "ACL",
            action: "all",
        },
    },
    {
        path: "/keyword-manager/search-suggestions",
        name: "keyword-manager-suggestions",
        component: () => import("@/views/keyword-manager/KeywordSuggestions.vue"),
        meta: {
            resource: "ACL",
            action: "all",
        },
    },
    {
        path: "/keyword-manager/keyword-search",
        name: "keyword-manager-keyword-search",
        component: () => import("@/views/keyword-manager/KeywordSearch.vue"),
        meta: {
            resource: "ACL",
            action: "all",
        },
    },
    {
        path: "/keyword-manager/keyword-analayzer-max",
        name: "keyword-manager-analayzer-max",
        component: () => import("@/views/keyword-manager/KeywordAnalyserMax.vue"),
        meta: {
            resource: "ACL",
            action: "all",
        },
    },
    {
        path: "/keyword-manager/new-keyword-import",
        name: "keyword-manager-new-keyword-import",
        component: () =>
          import("@/views/keyword-manager/NewKeywordImport.vue"),
        meta: {
          resource: "ACL",
          action: "all",
        },
    },
    {
        path: "/keyword-manager/new-keyword-research",
        name: "keyword-manager-new-keyword-research",
        component: () =>
          import("@/views/keyword-manager/NewKeywordResearch.vue"),
        meta: {
          resource: "ACL",
          action: "all",
        },
    }
];
