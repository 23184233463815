export default [
  {
    path: "/campaigns",
    name: "campaigns",
    component: () => import("@/views/ads-manager/campaign/Web/index.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/campaigns/generator",
    name: "campaigns-generator",
    component: () =>
      import("@/views/ads-manager/campaign/Web/create.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/campaigns/edit",
    name: "campaigns-edit",
    component: () =>
      import("@/views/ads-manager/campaign/Web/update.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
  {
    path: "/campaigns/ChartCompain",
    name: "chartCompain",
    component: () =>
      import("@/views/ads-manager/campaign/Web/ChartCompain.vue"),
    meta: {
      resource: "ACL",
      action: "all",
    },
  },
];
